import { defineComponent, PropType } from 'vue';
import type { TRouteType } from '@/types/routeType';

export default defineComponent({
  name: 'BaseIconButton',

  props: {
    onClickFunction: {
      type: Function
    },
    href: {
      type: Object as PropType<TRouteType>
    },
    isTargetBlank: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    toolTipText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['clicked'],
  setup(_, { emit }) {
    const clickHandler = () => {
      emit('clicked');
    };

    return {
      clickHandler
    };
  }
});
