<template>
  <div
    :class="`model-label model-${modelTypeId}type`"
  >
    <div class="label-text">
      {{ getModelTypeByValue }}
    </div>
  </div>
</template>

<script src="./ModelLabel.ts" lang="ts"/>
<style src="./ModelLabel.scss" lang="scss" scoped/>
