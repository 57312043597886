<template>
  <button
    v-if="!href"
    id="1"
    :class="`ui-icon-button ${isActive && 'active'}`"
    @click="clickHandler()"
    v-tooltip.top="toolTipText"
    :disabled="disabled"
  >
    <slot v-if="!isActive" name="button-image"></slot>
    <slot v-else name="button-image-active"></slot>
  </button>
  <router-link
    v-if="!!href"
    :class="`ui-icon-button ${isActive && 'active'}`"
    :to="href"
    :target="isTargetBlank && '_blank'"
    v-tooltip.top="toolTipText"
  >
    <slot v-if="!isActive" name="button-image"></slot>
    <slot v-else name="button-image-active"></slot>
  </router-link>
</template>

<script src="./BaseIconButton.ts" lang="ts"/>
<style src="./BaseIconButton.scss" lang="scss" />
