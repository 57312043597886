import { computed, defineComponent, PropType } from 'vue';
import { RegisteredModelTypes } from '@/types/modelType';
import InfoBoard from '@/components/Model/InfoBoard/InfoBoard.vue';
import { useStore } from 'vuex';
import BaseLoader from '@/components/BaseComponents/BaseLoader/BaseLoader.vue';

export default defineComponent({
  name: 'DashboardStatistic',

  components: {
    InfoBoard,
    BaseLoader
  },

  props: {
    data: {
      type: String as PropType<any>
    },
    label: {
      type: String,
      default: 'Заявки с трехмерными моделями'
    },
    statisticType: {
      type: Number as PropType<RegisteredModelTypes>,
      default: RegisteredModelTypes.INVENTION
    },
    isGeneral: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const blockClass = computed(() => {
      switch (props.statisticType) {
        case RegisteredModelTypes.INVENTION:
          return 'invention';
        case RegisteredModelTypes.TRADEMARK:
          return 'trademark';
        case RegisteredModelTypes.INDUSTRIAL_MODEL:
          return 'industrial';
        case RegisteredModelTypes.PARTICLE:
          return 'particle';
        default:
          return 'invention';
      }
    });

    const { state } = useStore();

    const requestCount = computed(() => {
      return (
        (state.statistics.content[RegisteredModelTypes.INVENTION]?.requestsCount || 0) +
        (state.statistics.content[RegisteredModelTypes.UTILITY_MODEL]?.requestsCount || 0) +
        (state.statistics.content[RegisteredModelTypes.INDUSTRIAL_MODEL]?.requestsCount || 0) +
        (state.statistics.content[RegisteredModelTypes.TRADEMARK]?.requestsCount || 0) +
        (state.statistics.content[RegisteredModelTypes.PARTICLE]?.requestsCount || 0)
      ) || 0;
    });

    return {
      blockClass,
      state,
      requestCount
    };
  }
});
