import { defineComponent } from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

export default defineComponent({
  name: 'StatisticsTabs',

  components: {
    TabView,
    TabPanel
  },

  props: {},

  setup() {
    return {};
  }
});
