import { computed, defineComponent } from 'vue';
import BaseTaggedAutoselect from '@/components/BaseComponents/BaseTaggedAutoselect/BaseTaggedAutoselect.vue';
import { ModelStatus, RegisteredModelTypes } from '@/types/modelType';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { STATISTICS_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import BaseInputCalendar from '@/components/BaseComponents/BaseInputCalendar/BaseInputCalendar.vue';
import { CalendarRanges, TChipUnit } from '@/types/calendarDefaultRanges';
import moment from 'moment/moment';
import { getQuarterBounds, setBeginOfDay, setEndOfDay, setMonthsAgo } from '@/utils/setDayTime';
// import { STATISTICS_ACTIONS_TYPE } from '@/store/names/action.name';

export default defineComponent({
  name: 'StatisticsFilters',

  components: {
    BaseTaggedAutoselect,
    BaseInputCalendar
  },

  setup() {
    const { commit, state } = useStore();

    const changeType = (value: any) => {
      commit(`${MODULES_NAMES.STATISTICS}/${STATISTICS_MUTATIONS_TYPE.SET_TYPE_STATISTIC}`, value);
    };

    const changeDates = (value: [Date, Date]) => {
      if (value === null) {
        commit(`${MODULES_NAMES.STATISTICS}/${STATISTICS_MUTATIONS_TYPE.SET_DATES_BETWEEN_STATISTIC}`, null);
        return;
      }
      if (value[1] !== null) {
        commit(`${MODULES_NAMES.STATISTICS}/${STATISTICS_MUTATIONS_TYPE.SET_DATES_BETWEEN_STATISTIC}`, [
          moment(setBeginOfDay(new Date(value[0]))).format('yyyy-MM-DD HH:mm:ss'),
          moment(setEndOfDay(new Date(value[1]))).format('yyyy-MM-DD HH:mm:ss')
        ]);
      }
    };

    const possibleTypes = computed(() => [
      { label: 'ИЗПМ', value: RegisteredModelTypes.INVENTION },
      { label: 'Промышленный образец', value: RegisteredModelTypes.INDUSTRIAL_MODEL },
      { label: 'Товарный знак', value: RegisteredModelTypes.TRADEMARK },
      { label: 'ИЗПМ: молекулы в трехмерном виде', value: RegisteredModelTypes.PARTICLE }
    ]);

    const chipsList = computed((): TChipUnit[] => [
      {
        key: CalendarRanges.HALF_YEAR,
        label: 'Полгода',
        dates: [setMonthsAgo(6), new Date()]
      },
      {
        key: CalendarRanges.QUARTER,
        label: 'Текущий квартал',
        dates: [getQuarterBounds()[0], new Date()]
      },
      {
        key: CalendarRanges.MONTH_AGO,
        label: 'Месяц',
        dates: [setMonthsAgo(), new Date()]
      }
    ]);

    return {
      state,
      chipsList,
      changeDates,
      changeType,
      possibleTypes,
      ModelStatus
    };
  }
});
