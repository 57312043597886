import { defineComponent, PropType } from 'vue';
import { RequestStatus } from '@/store/modules/statistic/state';

export default defineComponent({
  name: 'StatusBox',

  props: {
    count: {
      type: Number
    },
    status: {
      type: String as PropType<RequestStatus>,
      default: RequestStatus.CONVERTED
    }
  },

  components: {},

  setup(props) {
    const statusLocalize = () => {
      switch (props.status) {
        case RequestStatus.CONVERTED:
          return 'успешно';
        case RequestStatus.FAILED:
          return 'ошибка';
        case RequestStatus.PROCESSING:
          return 'обработка';
      }
    };

    const className = () => {
      switch (props.status) {
        case RequestStatus.FAILED:
          return 'error';
        case RequestStatus.PROCESSING:
          return 'processing';
      }
    };

    return {
      statusLocalize,
      className
    };
  }
});
