<template>
  <div class="statistics-filter-block">
    <div class="calendar-wrapper">
      <BaseInputCalendar
        name="date"
        placeholder="Период загрузки"
        @changed="changeDates($event)"
        :value="state.models.filters.date"
        has-remove-icon
        :chipsList="chipsList"
      />
    </div>
    <div class="type-wrapper">
      <BaseTaggedAutoselect
        placeholder="Тип OИС"
        :value="state.statistics.statisticFilters.type"
        :options="possibleTypes"
        name="type-filter"
        @input="changeType($event)"
      />
    </div>
  </div>
</template>

<script src="./StatisticsFilters.ts" lang="ts"/>
<style src="./StatisticsFilters.scss" lang="scss"/>
