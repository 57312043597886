<template>
  <StatisticsTabs>
    <template v-slot:statistic>
      <StatisticsFilters/>
      <StatisticsTable />
    </template>
    <template v-slot:requests>
      <div class="ui-title-large">
        Заявок: {{ state.statistics.statisticsPage?.totalElements || '-' }}
      </div>
      <RequestsFilters/>
      <div class="statistics-page">
        <div class="table-block">
          <RequestsTable/>
          <BasePaginator
            :total-records="state.statistics.statisticsPage?.totalElements || 0"
            @changed="onChangePagination($event)"
            :rows-per-page-options="defaultPaginatorSettings.selectSize"
            :size-rows="state.paginator.paginator.size"
            :first-page-prop="state.paginator.paginator.page"
            :total-pages="state.statistics.statisticsPage.totalPages"
          />
          <div
            class="p-col-12 ui-no-found-content"
            v-if="!state.statistics.isLoadingPage && !state.statistics.statisticsPage?.totalElements"
          >
            <img alt="not found" src="@/assets/images/no_results.svg"/>
            <div class="ui-title-large">Заявок по вашему запросу не найдено</div>
            <div>
              <div class="bold-text">
                Рекомендации:
              </div>
              <div class="text">
                1. Убедитесь, что фильтры заполнены правильно<br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </StatisticsTabs>
</template>

<script src="./PageStatistics.ts" lang="ts"/>
<style src="./PageStatistics.scss" lang="scss"/>
