<template>
  <Multiselect
    :class="`ui-tagged-autoselect ${className}`"
    v-model="valueInput"
    :mode="mode"
    :placeholder="placeholder"
    :options="options"
    @input="handleSelect($event)"
    :name="name"
  >
    <template v-slot:caret>
      <i class="pi pi-chevron-down"></i>
    </template>
  </Multiselect>
</template>

<style src="@vueform/multiselect/themes/default.css" />
<script src="./BaseTaggedAutoselect.ts" lang="ts"/>
<style src="./BaseTaggedAutoselect.scss" lang="scss"/>
