import { computed, defineComponent, onMounted } from 'vue';
import BaseTable from '@/components/BaseComponents/BaseTable/BaseTable.vue';
import { useStore } from 'vuex';
import ROUTE_NAMES from '@/router/route.names.json';
import { ModelFileFormats, ModelStatus, RegisteredModelTypes, UnitedRmts } from '@/types/modelType';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { STATISTICS_ACTIONS_TYPE } from '@/store/names/action.name';

export default defineComponent({
  name: 'StatisticsTable',

  components: {
    BaseTable
  },

  setup() {
    const { state, dispatch } = useStore();

    const columns = computed(() => {
      return [
        { title: 'Тип ОИС', colspan: 1 },
        { title: 'Кол-во заявок', colspan: 1 },
        { title: 'Кол-во 3D-моделей', colspan: 1 },
        { title: 'Выдано свидетельств о регистрации/патентов', colspan: 1 },
        { title: 'Ошибок загрузки', colspan: 1 }
      ];
    });

    const rows = computed(() => {
      const full = state.statistics.content ? Object.values(state.statistics.content)?.map((item: any) => {
        return { cells: item };
      }) : [];
      if (full.length) {
        full.length = full.length - 2;
        // TODO в функцию
        full.push({
          cells: {
            registeredModelType: {
              fullLocalizedName: 'Всего'
            },
            requestsCount: full.reduce((accumulator, currentValue) => accumulator + currentValue.cells.requestsCount, 0),
            patentsCount: full.reduce((accumulator, currentValue) => accumulator + currentValue.cells.patentsCount, 0),
            errorModelsCount: full.reduce((accumulator, currentValue) => accumulator + currentValue.cells.errorModelsCount, 0),
            processingModelsCount: full.reduce((accumulator, currentValue) => accumulator + currentValue.cells.processingModelsCount, 0),
            successModelsCount: full.reduce((accumulator, currentValue) => accumulator + currentValue.cells.successModelsCount, 0)
          }
        });
      }
      return full;
    });

    onMounted(() => {
      dispatch(`${MODULES_NAMES.STATISTICS}/${STATISTICS_ACTIONS_TYPE.GET_ALL_STATISTICS}`);
    });

    return {
      ModelStatus,
      ModelFileFormats,
      ROUTE_NAMES,
      state,
      UnitedRmts,
      RegisteredModelTypes,
      columns,
      rows
    };
  }
});
