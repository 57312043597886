import { computed, defineComponent } from 'vue';
import BaseTaggedAutoselect from '@/components/BaseComponents/BaseTaggedAutoselect/BaseTaggedAutoselect.vue';
import BaseInputText from '@/components/BaseComponents/BaseInputText/BaseInputText.vue';
import { RequestStatus } from '@/store/modules/statistic/state';
import { ModelStatus, RegisteredModelTypes } from '@/types/modelType';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { STATISTICS_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import type { HTMLInputEvent } from '@/types/eventTypes';
import BaseDropdown from '@/components/BaseComponents/BaseDropdown/BaseDropdown.vue';
import { STATISTICS_ACTIONS_TYPE } from '@/store/names/action.name';

export default defineComponent({
  name: 'RequestsFilters',

  components: {
    BaseTaggedAutoselect,
    BaseInputText,
    BaseDropdown
  },

  setup() {
    const { commit, state, dispatch } = useStore();

    const refreshStatistic = () => {
      dispatch(`${MODULES_NAMES.STATISTICS}/${STATISTICS_ACTIONS_TYPE.GET_ALL_STATISTICS}`);
    };

    const changeStatus = (value: RequestStatus) => {
      value = value || RequestStatus.ALL;
      commit(`${MODULES_NAMES.STATISTICS}/${STATISTICS_MUTATIONS_TYPE.SET_STATUS_FILTER}`, value);
    };

    const handleReset = () => {
      commit(`${MODULES_NAMES.STATISTICS}/${STATISTICS_MUTATIONS_TYPE.SET_QUERY_FILTER}`, '');
    };

    const changeQuery = (value: HTMLInputEvent) => {
      commit(`${MODULES_NAMES.STATISTICS}/${STATISTICS_MUTATIONS_TYPE.SET_QUERY_FILTER}`, value.target.value);
    };

    const changeType = (value: any) => {
      commit(`${MODULES_NAMES.STATISTICS}/${STATISTICS_MUTATIONS_TYPE.SET_TYPE_FILTER}`, value);
    };

    const possibleTypes = computed(() => [
      { label: 'ИЗПМ', value: RegisteredModelTypes.INVENTION },
      { label: 'Промышленный образец', value: RegisteredModelTypes.INDUSTRIAL_MODEL },
      { label: 'Товарный знак', value: RegisteredModelTypes.TRADEMARK },
      { label: 'ИЗПМ: молекулы в трехмерном виде', value: RegisteredModelTypes.PARTICLE }
    ]);

    const possibleStatus = computed(() => {
      return [
        { name: 'Все', value: RequestStatus.ALL },
        { name: 'Готовые', value: RequestStatus.CONVERTED },
        { name: 'В процессе', value: RequestStatus.PROCESSING },
        { name: 'Ошибки', value: RequestStatus.FAILED }
      ];
    });

    return {
      refreshStatistic,
      state,
      handleReset,
      changeType,
      possibleTypes,
      changeStatus,
      changeQuery,
      possibleStatus,
      ModelStatus
    };
  }
});
