<template>
  <div :class="`dashboard ${blockClass}`">
    <div class="label">{{ label }}</div>
    <div class="dashboard-overlay">
      <div class="first-border"></div>
      <div
        v-if="!isGeneral"
        class="dashboard-content"
      >
        <InfoBoard
          :showed-model-types="[statisticType]"
        />
      </div>
      <div
        v-else
        class="dashboard-content"
      >
        <div class="general-request-count p-jc-center p-ai-center">
          <BaseLoader
            backgroundColorRGBA="rgba(1,1,1, .4)"
            v-if="state.statistics.isLoading"
          />
          <span class="main-color ui-title-large">{{ requestCount }}</span>
          <span>общее число</span>
        </div>
      </div>
      <div class="last-border"></div>
    </div>
  </div>
</template>

<script src="./DashboardStatistic.ts" lang="ts"/>
<style src="./DashboardStatistic.scss" lang="scss"/>
