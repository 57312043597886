<template>
  <BaseTable
    :columns="columns"
    :rows="rows"
    ref="modelTableRef"
    :is-loading="state.statistics.isLoadingPage"
    class-name="models-table statistics-table"
  >
    <template #row="{ data, index }">
      <tr :class="`${data?.cells[1]?.status === RequestStatus.FAILED ? 'error' : ''}`">
        <td>
          <router-link
            class="request-link"
            target="_blank"
            :to="{ name: ROUTE_NAMES.PAGE_REQUEST, params: { id: data?.cells[0]?.requestId } }"
          >
            {{ getModelValue(data?.cells[1]?.requestId) }}
          </router-link>
        </td>
        <td>
          {{ UnitedRmts[RegisteredModelTypes[data?.cells[2]?.models[0]?.registeredModelType.id]] || '' }}
        </td>
        <td>
            <button
              class="request-link"
              @click="toggleAddInfo(index)"
            >
            {{ data?.cells[1]?.models.length }}
          </button>
        </td>
        <td>
          <StatusBox
            v-if="!!data?.cells[1].status"
            :count="countErrorOrProcessingModel(data?.cells[3]) "
            :status="data?.cells[1].status"
          />
        </td>
      </tr>
    </template>
    <template #additional-info="{ data }">
      <div class="models-link-wrapper">
        <div
          v-for="item in data?.cells[1]?.models"
          :key="item.id"
        >
          <router-link
            v-if="!(item.status === ModelStatus.FAILED || item.status === ModelStatus.PROCESSING || item.status === ModelStatus.NEW)"
            class="model-link"
            target="_blank"
            :to="{ name: ROUTE_NAMES.PAGE_MODEL_VIEW, params: { id: item.modelUuid } }"
          >
            <img :src="getAuthImageForCard(item)" alt="img">
          </router-link>
          <div
            v-if="item.status === ModelStatus.FAILED"
            class="error-model no-prev-model-block"
          >
            <i class="pi pi-exclamation-circle"></i>
            <span>Ошибка загрузки</span>
          </div>
          <div
            v-if="item.status === ModelStatus.PROCESSING"
            class="processing-model no-prev-model-block"
          >
            <i class="pi pi-clock"></i>
            <span>Обработка</span>
          </div>
        </div>
      </div>
    </template>
  </BaseTable>
</template>

<script src="./RequestsTable.ts" lang="ts"/>
<style src="./RequestsTable.scss" lang="scss"/>
