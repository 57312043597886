<template>
  <BaseTable
    :columns="columns"
    :rows="rows"
    :is-loading="state.statistics.isLoading"
    class-name="models-table main-statistic-table"
  >
    <template #row="{ data, index }">
      <tr :class="(index === rows.length - 1) && 'total'">
        <td :class="(index === rows.length - 1) && 'bold-text'">
          {{ data.cells.registeredModelType?.fullLocalizedName?.replace(/'/g, '') }}
        </td>
        <td>
          {{ data.cells.requestsCount }}
        </td>
        <td>
          {{ data.cells.successModelsCount + data.cells.processingModelsCount + data.cells.errorModelsCount }}
        </td>
        <td>
          {{ data.cells.patentsCount }}
        </td>
        <td class="ui-error-color">
          {{ data.cells.errorModelsCount }}
        </td>
      </tr>
    </template>
  </BaseTable>
</template>

<script src="./StatisticsTable.ts" lang="ts"/>
<style src="./StatisticsTable.scss" lang="scss"/>
