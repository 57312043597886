import { computed, defineComponent, PropType } from 'vue';
import { MODEL_TYPE_SMALL } from '@/dictionaries/modelData/model.type';
import { RegisteredModelTypes } from '@/types/modelType';

export default defineComponent({
  name: 'ModelLabel',

  props: {
    modelTypeId: {
      type: Number as PropType<RegisteredModelTypes>
    }
  },

  setup(props) {
    const getModelTypeByValue = computed(() => {
      const modelItem = Object.values(MODEL_TYPE_SMALL)
        .find(item => (item.value === props.modelTypeId));
      return modelItem?.label;
    });

    return {
      getModelTypeByValue
    };
  }
});
