import { defineComponent, onMounted, watch } from 'vue';
import BaseTaggedAutoselect from '@/components/BaseComponents/BaseTaggedAutoselect/BaseTaggedAutoselect.vue';
import InfoHeadForPage from '@/components/InfoHeadForPage/InfoHeadForPage.vue';
import RequestsFilters
  from '@/components/Statistics/PageStatistics/components/RequestsFilters/RequestsFilters.vue';
import DashboardStatistic
  from '@/components/Statistics/PageStatistics/components/DashboardStatistic/DashboardStatistic.vue';
import { RegisteredModelTypes } from '@/types/modelType';
import RequestsTable from '@/components/Statistics/PageStatistics/components/RequestsTable/RequestsTable.vue';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { STATISTICS_ACTIONS_TYPE } from '@/store/names/action.name';
import BasePaginator from '@/components/BaseComponents/BasePaginator/BasePaginator.vue';
import defaultPaginatorSettings from '@/dictionaries/defaultPaginatorSettings/defaultPaginatorSettings';
import type { TEventPaginator } from '@/types/eventTypes';
import { PAGINATOR_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import { useRoute } from 'vue-router';
import { debounce } from 'lodash';
import appendToastMessage from '@/utils/appendToastMessage';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import setPaginatorAndFiltersFromQuery from '@/components/Model/utils/setPaginatorAndFiltersFromQuery';
import { AbortError } from '@/services/utils/errors/abortError';
import { isExpertOnly } from '@/utils/rolesUtils';
import StatisticsTabs from '@/components/Statistics/PageStatistics/components/StatisticsTabs/StatisticsTabs.vue';
import StatisticsFilters
  from '@/components/Statistics/PageStatistics/components/StatisticsFilters/StatisticsFilters.vue';
import StatisticsTable from '@/components/Statistics/PageStatistics/components/StatisticsTable/StatisticsTable.vue';

export default defineComponent({
  name: 'PageStatistics',

  components: {
    BaseTaggedAutoselect,
    InfoHeadForPage,
    RequestsFilters,
    DashboardStatistic,
    RequestsTable,
    BasePaginator,
    StatisticsTabs,
    StatisticsFilters,
    StatisticsTable
  },

  setup() {
    const {
      dispatch,
      state,
      commit
    } = useStore();
    const route = useRoute();

    const getRequestList = async() => {
      try {
        await dispatch(`${MODULES_NAMES.STATISTICS}/${STATISTICS_ACTIONS_TYPE.GET_STATISTICS_PAGE}`);
      } catch (err) {
        if (err.name !== AbortError.name) {
          appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
        }
      }
    };
    const debouncedGetRequestList = debounce(getRequestList, 300);
    const updateList = async() => {
      await debouncedGetRequestList();
    };

    const getStatisticList = async() => {
      try {
        await dispatch(`${MODULES_NAMES.STATISTICS}/${STATISTICS_ACTIONS_TYPE.GET_ALL_STATISTICS}`, state.statistics.statisticFilters?.type);
      } catch (err) {
        console.error(err);
        if (err.name !== AbortError.name) {
          appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
        }
      }
    };
    const debouncedGetStatisticList = debounce(getStatisticList, 300);
    const updateStatistic = async() => {
      await debouncedGetStatisticList();
    };

    onMounted(() => {
      setPaginatorAndFiltersFromQuery(route.query);
      updateList();
      updateStatistic();
    });

    watch(() => state.statistics.statisticFilters.dates, () => {
      updateStatistic();
    });

    watch(() => state.statistics.statisticFilters.type, () => {
      updateStatistic();
    });

    watch(() => state.paginator.paginator, () => {
      updateList();
    });

    const onChangePagination = (event: TEventPaginator) => {
      commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_PAGINATOR}`, {
        size: event.rows,
        page: event.page
      });
    };

    return {
      isExpertOnly,
      onChangePagination,
      defaultPaginatorSettings,
      RegisteredModelTypes,
      state
    };
  }
});
