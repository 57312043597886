<template>
  <div class="request-filter-block">
    <BaseInputText
      name="query-statistics"
      @input="changeQuery($event)"
      @reset="handleReset()"
      :value="state.statistics.filters.query"
      has-remove-icon
      icon="pi-search"
      placeholder="Поиск"
      class="query-input"
    />
    <BaseTaggedAutoselect
      placeholder="Тип ОИС"
      :value="state.statistics.filters.type"
      :options="possibleTypes"
      name="type-filter"
      @input="changeType($event)"
    />
    <BaseDropdown
      showClear
      name="status"
      placeholder="Статус загрузки"
      :value="possibleStatus"
      @input="changeStatus($event?.value)"
      class="dd-input"
    />
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css" />
<script src="./RequestsFilters.ts" lang="ts"/>
<style src="./RequestsFilters.scss" lang="scss"/>
