<template>
  <TabView>
    <TabPanel header="СТАТИСТИКА">
      <slot name="statistic">
      </slot>
    </TabPanel>
    <TabPanel header="ЗАЯВКИ">
      <slot name="requests">
      </slot>
    </TabPanel>
  </TabView>
</template>

<script src="./StatisticsTabs.ts" lang="ts"/>
<style src="./StatisticsTabs.scss" lang="scss"/>
