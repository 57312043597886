import { defineComponent, ref, watch } from 'vue';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  name: 'BaseTaggedAutoselect',

  components: { Multiselect },

  props: {
    value: {
      type: Array
    },
    options: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      default: 'tags'
    },
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    className: {
      type: String
    }
  },

  emits: ['input'],

  setup(props, { emit }) {
    const valueInput = ref(props.value);

    watch(() => props.value, () => {
      valueInput.value = props.value;
    });

    const handleSelect = () => {
      emit('input', valueInput);
    };

    return { valueInput, handleSelect };
  }
});
