import { computed, defineComponent, ref } from 'vue';
import BaseTable from '@/components/BaseComponents/BaseTable/BaseTable.vue';
import { useStore } from 'vuex';
import { createdAt } from '@/components/Model/ModelCards/utils/createdAt';
import BaseIconButton from '@/components/BaseComponents/BaseIconButton/BaseIconButton.vue';
import BaseAuthCardImg from '@/components/BaseComponents/BaseAuthCardImg/BaseAuthCardImg.vue';
import ROUTE_NAMES from '@/router/route.names.json';
import { ModelFileFormats, ModelStatus, RegisteredModelTypes, TModelData, UnitedRmts } from '@/types/modelType';
import ModelLabel from '@/components/Model/ModelLabel/ModelLabel.vue';
import { getModelValue } from '@/components/Model/ModelCards/utils/getModelValue';
import StatusBox from '@/components/Statistics/PageStatistics/components/StatusBox/StatusBox.vue';
import { RequestStatus } from '@/store/modules/statistic/state';
import { getAuthImage } from '@/utils/getAuthImage';
import { getModelImageUrl } from '@/components/Model/utils/getImageUrl';

export default defineComponent({
  name: 'RequestsTable',

  components: {
    BaseTable,
    BaseIconButton,
    BaseAuthCardImg,
    ModelLabel,
    StatusBox
  },

  emits: ['clickCompare'],

  setup() {
    const { state } = useStore();
    const modelTableRef = ref();

    const columns = computed(() => {
      return [
        { title: 'Номер заявки', colspan: 1 },
        { title: 'Тип', colspan: 1 },
        { title: 'Трехмерные модели', colspan: 1 },
        { title: 'Статус загрузки', colspan: 1 }
      ];
    });

    const rows = computed(() => {
      return state.statistics.statisticsPage.content.length ? state.statistics.statisticsPage.content?.map((item: TModelData) => {
        return { cells: [item, item, item, item] };
      }) : [];
    });

    const toggleAddInfo = (index: number) => {
      modelTableRef.value.toggleVisible(index);
    };

    const countErrorOrProcessingModel = (data: any) => {
      const errCount = data.models.reduce((sum: number, current: any) => {
        if (current.status === ModelStatus.FAILED) {
          sum++;
        }
        return sum;
      }, 0);
      const procCount = data.models.reduce((sum: number, current: any) => {
        if (current.status === ModelStatus.PROCESSING) {
          sum++;
        }
        return sum;
      }, 0);
      return errCount || procCount;
    };

    const getAuthImageForCard = (data: any) => {
      if (data.modelFileFormat?.id === ModelFileFormats.PRC) {
        return require('@/assets/images/PRC.svg');
      }
      return getAuthImage(getModelImageUrl(data.id!));
    };

    return {
      countErrorOrProcessingModel,
      getModelValue,
      ModelStatus,
      ModelFileFormats,
      ROUTE_NAMES,
      state,
      createdAt,
      toggleAddInfo,
      modelTableRef,
      UnitedRmts,
      RegisteredModelTypes,
      columns,
      rows,
      RequestStatus,
      getAuthImageForCard
    };
  }
});
